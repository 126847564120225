<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000667')},
        {value: 'inventoryImport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000697')},
        {value: 'productGoodsSuggestPriceImport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000719')},
        {value: 'productGoodsStatusImport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005533')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
